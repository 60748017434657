import type {AssignmentEngagementList} from '~/types/assignment-engagement-list.interface';

export default defineNuxtRouteMiddleware(async _ => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const {fetchAssignmentEngagements, setCurrentAssignmentEngagements} =
    useAssignmentStore();

  const {data, status} = await fetchAssignmentEngagements();

  if (status.value === 'success') {
    setCurrentAssignmentEngagements(data.value! as AssignmentEngagementList);
  }
});
